import * as React from "react";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import { Grid } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Title from "./Title";
import { getYear, getDaysInMonth, format, parseISO } from "date-fns";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
const useStyles = makeStyles({
  header: {
    fontWeight: "bold",
    textAlign: "left",
  },
  table: {
    textAlign: "center",
    fontWeight: "bold",
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
});
export default function Notifications() {
  const [error, setError] = useState("");
  const generateOptions = (start, end) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push(i);
    }
    return options;
  };
  const years = generateOptions(getYear(new Date()) - 100, getYear(new Date()));
  const days = generateOptions(1, getDaysInMonth(new Date()));
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const addnotification = async (data) => {
    setError("");
    const finalData = {
      ...data,
    };
    console.log(finalData);
    try {
      const response = await axios.post(
        `https://api.freestore.io/notification`,
        finalData
      );
      if (response.status === 200) {
        // fetchData();
        console.log("notification added successfully!");
        // Clear the data in the form from control
        reset();
        // Refresh the component
        fetchData();
      }
    } catch (error) {
      console.error("There was an error!", error);
      // Set the error
      setError(
        " There was an error creating the notification. Please try another email or check your connection."
      );
    }
  };
  const addnotificationForm = (
    <>
      <Card variant="outlined">
        <CardContent>
          <Title>Add a new notification</Title>
          <form onSubmit={handleSubmit(addnotification)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Box mb={0}>
                  <InputLabel htmlFor="name">Content</InputLabel>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Content is required." }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        style={{ width: "100%" }}
                        error={Boolean(errors.name)}
                        helperText={errors.name?.message}
                      />
                    )}
                  />
                </Box>
              </Grid>
              {/* New Line */}
              <Grid item xs={4} md={4}>
                <Box mb={0}>
                  <InputLabel htmlFor="name">
                    Trigger Code (Pi Only, leave it blank)
                  </InputLabel>
                  <Controller
                    name="trigger"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        style={{ width: "100%" }}
                        error={Boolean(errors.trigger)}
                        helperText={errors.trigger?.message}
                      />
                    )}
                  />
                </Box>
                <p>
                  {" "}
                  If the trigger code is "initialReward", it's a special trigger
                  code
                </p>
                {/* Add a red paragraph */}
                <p style={{ color: "red" }}>
                 <b>Note: </b> Please note that adding or removing notifications take some time since it has to edit the data for all users (notifications are personalized).
                  </p>
              </Grid>
            </Grid>

            <Box mt={2}>
              {/* If seterror is not empty display in red text */}
              {error && <Typography color="error">{error}</Typography>}
              <br />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onSubmit={addnotification}
                style={{ backgroundColor: "#277d97" }}
              >
                Add notification
              </Button>
            </Box>
            {/* Other hidden fields */}
            <Box mb={2}>
              <Controller
                name="notificationID"
                control={control}
                defaultValue={""} // Default value should be set accordingly.
                render={({ field }) => <input type="hidden" {...field} />}
              />
            </Box>
            <Box mb={2}>
              <Controller
                name="addedDate"
                control={control}
                defaultValue={new Date().toISOString().substring(0, 10)} // Default value is current date
                render={({ field }) => <input type="hidden" {...field} />}
              />
            </Box>
          </form>
        </CardContent>
      </Card>
    </>
  );
  // Rest of Form
  const [Notifications, setNotifications] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [editingId, setEditingId] = React.useState("");
  const [editingnotification, setEditingnotification] = React.useState({});
  const fetchData = async () => {
    setLoading(true);
    const result = await axios("https://api.freestore.io/notifications");
    setNotifications(result.data);
    setLoading(false);
    // "" the editing id
    setEditingId("");
  };
  const handleEdit = (notification) => {
    setEditingId(notification.notificationID);
    setEditingnotification(notification);
  };
  const handleConfirm = async () => {
    // Console log the editing notification
    console.log(editingnotification);
    // Try result and handle error
    const result = await axios.put(
      `https://api.freestore.io/notification/${editingId}`,
      editingnotification
    );
    if (result.status === 200) {
      setEditingId("");
      fetchData();
    }
  };
  const handleDelete = async (id) => {
    const result = await axios.delete(
      `https://api.freestore.io/notification/${id}`
    );
    if (result.status === 200) {
      fetchData();
    }
  };
  React.useEffect(() => {
    fetchData();
  }, []);
  const classes = useStyles();
  if (loading) {
    return (
      <div className={classes.progress}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <React.Fragment>
      <Title>Notifications</Title>
      {addnotificationForm}
      <TableContainer>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              {/* your header */}
              <TableCell className={classes.header}>
                Notification Content
              </TableCell>
              <TableCell className={classes.header}>Date Added</TableCell>
              <TableCell className={classes.header}>Trigger Code</TableCell>
              <TableCell className={classes.header}>Edit</TableCell>
              <TableCell className={classes.header}>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Notifications.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
              .reverse()
              .map((notification) => {
                const date = parseISO(notification.addedDate);
                const formattedDate = format(date, "eee, MMM dd, yyyy");
                return notification.notificationID === editingId ? (
                  <TableRow key={notification.notificationID}>
                    <TableCell>
                      <TextField
                        value={editingnotification.name}
                        onChange={(e) =>
                          setEditingnotification({
                            ...editingnotification,
                            name: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="date"
                        value={editingnotification.addedDate}
                        onChange={(e) =>
                          setEditingnotification({
                            ...editingnotification,
                            addedDate: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={editingnotification.trigger}
                        onChange={(e) =>
                          setEditingnotification({
                            ...editingnotification,
                            trigger: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton aria-label="confirm" onClick={handleConfirm}>
                        <CheckIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      {/* If the notification.trigger is not initialReward */}
                      {editingnotification.trigger !== "initialReward" && (
                        <IconButton
                          aria-label="delete"
                          onClick={() =>
                            handleDelete(notification.notificationID)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow key={notification.notificationID}>
                    {/* your normal table cells here */}
                    <TableCell>{notification.name}</TableCell>
                    <TableCell>{formattedDate}</TableCell>
                    <TableCell>{notification.trigger}</TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleEdit(notification)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      {/* If the notification.trigger is not initialReward */}
                      {notification.trigger !== "initialReward" && (
                        <IconButton
                          aria-label="delete"
                          onClick={() =>
                            handleDelete(notification.notificationID)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={Notifications.length}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) =>
            setRowsPerPage(parseInt(event.target.value, 10))
          }
        />
      </TableContainer>
    </React.Fragment>
  );
}
