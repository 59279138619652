import * as React from 'react';
import { Link } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// Import delivery icon for truck (local_shipping)
import LocalShipping from '@mui/icons-material/LocalShipping';
import DashboardIcon from '@mui/icons-material/Dashboard';
import QrCode from '@mui/icons-material/QrCode';
// Add logout icon
import Logout from '@mui/icons-material/Logout';
// Import Notifications icon
import NotificationAdd from '@mui/icons-material/NotificationAdd';
import SupervisorAccount  from '@mui/icons-material/SupervisorAccount';
import Settings from '@mui/icons-material/Settings';
import Subscriptions from '@mui/icons-material/Subscriptions';
import Person from '@mui/icons-material/Person';
import Inventory2  from '@mui/icons-material/Inventory2';
// import sell
import Sell from '@mui/icons-material/Sell';
import ServerControl from './ServerControl';
import Container from '@mui/material/Container';

const linkStyle = { color: 'inherit', textDecoration: 'none' }; // add this

export const mainListItems = (
  <React.Fragment>
    {/* ------------- */}
    <Link to="/qrcodes" style={linkStyle}> {/* and use here */}
      <ListItemButton>
        <ListItemIcon>
          <QrCode />
        </ListItemIcon>
        <ListItemText primary="QR Codes" />
      </ListItemButton>
    </Link>
    {/* ------------- */}
    <Link to="/clients" style={linkStyle}>
      <ListItemButton>
        <ListItemIcon>
          <Person/>
        </ListItemIcon>
        <ListItemText primary="Clients" />
      </ListItemButton>
    </Link>
    {/* ------------- */}
    <Link to="/users" style={linkStyle}>
      <ListItemButton>
        <ListItemIcon>
          <SupervisorAccount/>
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItemButton>
    </Link>
    {/* ------------- */}
    <Link to="/offers" style={linkStyle}>
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Offers" />
      </ListItemButton>
    </Link>
    {/* ------------- */}
    <Link to="/ads" style={linkStyle}>
      <ListItemButton>
        <ListItemIcon>
          <Subscriptions />
        </ListItemIcon>
        <ListItemText primary="Ads" />
      </ListItemButton>
    </Link>
    {/* ------------- */}
    <Link to="/products" style={linkStyle}>
      <ListItemButton>
        <ListItemIcon>
          <Inventory2 />
        </ListItemIcon>
        <ListItemText primary="Products" />
      </ListItemButton>
    </Link>
    {/* ------------- */}
    <Link to="/tags" style={linkStyle}>
      <ListItemButton>
        <ListItemIcon>
          <Sell />
        </ListItemIcon>
        <ListItemText primary="Tags" />
      </ListItemButton>
    </Link>
    {/* ------------- */}
    <Link to="/notifications" style={linkStyle}>
      <ListItemButton>
        <ListItemIcon>
          <NotificationAdd />
        </ListItemIcon>
        <ListItemText primary="Notifications" />
      </ListItemButton>
    </Link>
    {/* Add deliveries */}
    <Link to="/deliveries" style={linkStyle}>
      <ListItemButton>
        <ListItemIcon>
          <LocalShipping />
        </ListItemIcon>
        <ListItemText primary="Deliveries" />
      </ListItemButton>
    </Link>
    {/* ------------- */}
    <Link to="/settings" style={linkStyle}>
      <ListItemButton>
        <ListItemIcon>
          <Settings />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItemButton>
    </Link>
    {/* IMplement a logout  */}
    <ListItemButton onClick={() => {
      localStorage.setItem('signedin', false);
      window.location.href = '/';
    }}>
      <ListItemIcon>
        <Logout />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItemButton>
    <br/>
    <Container style={{ marginTop: 40 }}>
      <ServerControl />
    </Container>
  </React.Fragment>
);
