import React from 'react';
import { BrowserRouter, Routes, Route, useLocation,Navigate } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import QRs from './components/QRs';
import Clients from './components/Clients';
import Users from './components/Users';
import Offers from './components/Offers';
import Ads from './components/Ads';
import Products from './components/Products';
import SignIn from './components/SignIn';
import Tags from './components/Tags';
import Notifications from './components/Notifications';
import Settings from './components/Settings';
import Delivery from './components/Delivery';

// PrivateRoute component
const PrivateRoute = ({children}) => {
  const signedIn = localStorage.getItem('signedin') === 'true';
  const location = useLocation();

  return signedIn ? children : <Navigate to="/" state={{ from: location }} />;
};

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignIn />} />
        {/* Add tags */}
        <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
        <Route path="/tags" element={<PrivateRoute><Tags /></PrivateRoute>} />
        <Route path="/clients" element={<PrivateRoute><Clients /></PrivateRoute>} />
        <Route path="/users" element={<PrivateRoute><Users /></PrivateRoute>} />
        <Route path="/products" element={<PrivateRoute><Products /></PrivateRoute>} />
        <Route path="/ads" element={<PrivateRoute><Ads /></PrivateRoute>} />
        <Route path="/offers" element={<PrivateRoute><Offers /></PrivateRoute>} />
        <Route path="/qrcodes" element={<PrivateRoute><QRs /></PrivateRoute>} />
        <Route path="/notifications" element={<PrivateRoute><Notifications /></PrivateRoute>} />
        <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
        {/* Add /deliveries */}
        <Route path="/deliveries" element={<PrivateRoute><Delivery /></PrivateRoute>} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
