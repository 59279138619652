import * as React from "react";
import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import Select from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import DownloadIcon from '@mui/icons-material/Download';
import { Grid } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Title from "./Title";
import { getYear, getDaysInMonth, format, parseISO, set } from "date-fns";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import ImageUploader from "./ImageUploader";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";

const useStyles = makeStyles({
  header: {
    fontWeight: "bold",
    textAlign: "left",
  },
  table: {
    textAlign: "center",
    fontWeight: "bold",
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
});

export default function Offers() {
  // Define videourl
  const [videoURL, setVideoURL] = useState("");
  const [loadingThumbnail, setloadingThumbnail] = useState(false);
  // Declare an int state with defalt value 2, call it videoTime
  const [videoTime, setVideoTime] = useState(2);
  // Declare Video Form Name
  const [videoFormName, setVideoFormName] = useState("");
// For Error Dialog
const [open, setOpen] = useState(false);
// Set dialog message
const [dialogMessage, setDialogMessage] = useState("");
// Set dialogue title
const [dialogTitle, setDialogTitle] = useState("");
// Declare btnCloseMessage
const [btnCloseMessage, setBtnCloseMessage] = useState("Close");

const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
  // Reset dialogs
  setDialogMessage("");
  setDialogTitle("");
  setBtnCloseMessage("Close");setEditingId("");
  
};
  const getVideoThumbnail = async (videoID) => {
    // If the video id is not empty
    if (videoID !== "") {
      const trimmedID = videoID.replace(".mp4", "");
      setVideoFormName(trimmedID);
      setloadingThumbnail(true);
      // Cast videoTime into an int
      const videoTimeInt = parseInt(videoTime);
      let videoLocation = `https://api.freestore.io/video/${trimmedID}/${videoTimeInt}}`;
      // remove the last 3 characters from videoLocation
      videoLocation = videoLocation.slice(0, -1);
      console.log(videoLocation);
      try {
        const response = await fetch(
          videoLocation
        );
        const data = await response.json();
        if (data) {
          setVideoURL(data.base64);
        }
        setloadingThumbnail(false);
      } catch (error) {
        // Empty the video url
        setVideoURL("");
        setBtnCloseMessage("Alright");
        console.error("Error:", error);
        setDialogMessage("Error: " + error);
        setDialogTitle("😔 Error, can't fetch the video thumbnail.");
        setDialogMessage("I am sorry but the video you provided does not seem to exist. Please check again.")
        // Open the dialog
        handleClickOpen();
        setloadingThumbnail(false);
      }
    }
  };
  
  // Declare a string state for error levels
  const [error, setError] = useState("");
  // Declare imageID state
  const [imageID, setImageID] = useState("");
  const [clients, setClients] = useState([]);

  // Generate options for select
  const generateOptions = (start, end) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push(i);
    }
    return options;
  };

  const years = generateOptions(getYear(new Date()) - 100, getYear(new Date()));
  const days = generateOptions(1, getDaysInMonth(new Date()));

  useEffect(() => {
    const getClients = async () => {
      try {
        const response = await axios.get("https://api.freestore.io/clients");
        setClients(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    getClients();
  }, []);

  // Add Data Form
  // Add client form in a card
  // Initialize form validation
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const hasVideo = watch("hasVideo");
  const hasImage = watch("hasImage");

  // Declare handleDownload
  const handleDownload = async (videoID) => {
    // Console log the id
    console.log(videoID);
    // Open a new window
    window.open(`https://api.freestore.io/downloadData/Offer/${videoID}`);
  };

  const handleUploadsuccess = (imageName) => {
    // alert("Image uploaded successfully");
    // console log the image name
    setImageID(imageName.data);
    console.log(`Image uploaded successfully with name: ${imageName.data}`);
    // Handle further logic here
  };

  // Add client function
  const AddOffer = async (data) => {
    // Convert tokens to int
    data.tokens = parseInt(data.tokens);
    // Adding Offer console log
    console.log("Adding Offer...");

    // Set error to none
    setError("");
    // Log the data
    // Auto generate password and append it to body

    // Merge dateOfBirth into form data
    const finalData = {
      ...data,
      imageID: imageID,
      wishlistedBy: [],
      viewedBy: [],
      tags: [],
      thumbnail: videoURL,
    };
    console.log(finalData);
    try {
      const response = await axios.post(
        `https://api.freestore.io/Offer`,
        finalData
      );
      if (response.status === 200) {
        // fetchData();
        console.log("Add Added successfully!");
        // Clear the data in the form from control
        reset();
        // Refresh the component
        fetchData();
      }
    } catch (error) {
      console.error("There was an error!", error);
      // Set the error
      setError(
        " There was an error creating the Offer. Please try another email or check your connection."
      );
    }
  };

  // Inside your component...
  const AddOfferForm = (
    <>
      <Card variant="outlined">
        <CardContent>
          <Title>Add a new Offer</Title>
          <form onSubmit={handleSubmit(AddOffer)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="title"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Title is required." }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Title"
                      error={Boolean(errors.title)}
                      helperText={errors.title?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="clientid"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Client ID is required." }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="Client ID"
                      error={Boolean(errors.clientID)}
                      helperText={errors.clientID?.message}
                      fullWidth
                    >
                      {/* if clients is not empty */}
                      {clients.length > 0 &&
                        clients.map((client) => (
                          <MenuItem
                            key={client.clientID}
                            value={client.clientID}
                          >
                            {client.name}
                          </MenuItem>
                        ))}
                        {/* else */}
                        {clients.length === 0 && (
                          <MenuItem value={0}>No clients found</MenuItem>
                        )}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  name="description"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Description is required." }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Description"
                      error={Boolean(errors.description)}
                      helperText={errors.description?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={8} sm={8}>
                <Controller
                  name="read_more_url"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="🔗 Read More URL (optional)"
                      error={Boolean(errors.read_more_url)}
                      helperText={errors.read_more_url?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <Controller
                  name="read_more_description"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="🔗 Read More Text"
                      error={Boolean(errors.read_more_description)}
                      helperText={errors.read_more_description?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  name="terms"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="📄 Terms & Conditions (write with <br />)"
                      error={Boolean(errors.terms)}
                      helperText={errors.terms?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  name="google_maps_url"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="📍 Google Maps URL (e.g. https://goo.gl/maps/2Rjx3XWAs7dvFxgJ9)"
                      error={Boolean(errors.google_maps_url)}
                      helperText={errors.google_maps_url?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3} sm={3}>
                <Controller
                  name="promocode"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Promo Code"
                      error={Boolean(errors.promocode)}
                      helperText={errors.promocode?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3} sm={3}>
                <Controller
                  name="unlockcode"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field }
                      label="🔒 Unlock Code"
                      error={Boolean(errors.unlockcode)}
                      helperText={errors.unlockcode?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3} sm={3}>
                <FormControlLabel
                  control={
                    <Controller
                      name="isFeatured"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => <Checkbox {...field} />}
                    />
                  }
                  label="Is Featured"
                />
              </Grid>
              <Grid item xs={3} sm={3}>
                <Controller
                  name="tokens"
                  control={control}
                  defaultValue={0}
                  rules={{
                    required: "Tokens are required.",
                    valueAsNumber: true,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Tokens"
                      type="number"
                      error={Boolean(errors.tokens)}
                      helperText={errors.tokens?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={3} sm={3}>
                <Controller
                  name="tokens_reward"
                  control={control}
                  defaultValue={0}
                  rules={{
                    required: "Tokens are required.",
                    valueAsNumber: true,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Tokens (Reward)"
                      type="number"
                      error={Boolean(errors.tokens)}
                      helperText={errors.tokens?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Controller
                name="AddedDate"
                control={control}
                defaultValue={new Date().toISOString().substring(0, 10)} // Default value is current date
                render={({ field }) => <input type="hidden" {...field} />}
              />
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Controller
                      name="hasVideo"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => <Checkbox {...field} />}
                    />
                  }
                  label="Has Video"
                />
              </Grid>
              {hasVideo && (
                <>
                {/* Video Uplaod Bar */}
              <Grid item xs={12} sm={6}>
                <Controller
                  name="videoUrl"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Video URL is required when video is included.",
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Video Name (add .mp4)"
                      error={Boolean(errors.videoUrl)}
                      // On change get video thumbnail
                      onChange={(e) => {
                        field.onChange(e); // This is necessary to keep react-hook-form working
                        getVideoThumbnail(e.target.value);
                      }}
                      helperText={errors.videoUrl?.message}
                      fullWidth
                    />
                  )}
                />
                {/* Add a link to https://aws.amazon.com/s3/ */}
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ marginTop: "10px" }}
                >
                  Upload video to{" "}
                  <a
                    href="https://aws.amazon.com/s3/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Amazon S3
                  </a>{" "}
                  and paste the Video Name (add .mp4) here.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                {loadingThumbnail ? (
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress />
                  </Box>
                ) : (
                  videoURL && (
                    <>
                      <img
                        width={400}
                        src={`data:image/jpeg;base64,${videoURL}`}
                        alt="Video Thumbnail"
                      />
                      <p>Thumbnail auto extracted from the video.</p>
                    </>
                  )
                )}
              </Grid>

              {/* Add another grid */}
              <Grid item xs={12} sm={6}>
                <TextField
                  type="number"
                  // label
                  label="Thumbnail Time (Seconds)"
                  value={videoTime}
                  // On change get video thumbnail
                  onChange={(e) => {
                    // This is necessary to keep react-hook-form working
                    setVideoTime(e.target.value);
                    getVideoThumbnail(videoFormName);
                  }}
                />
              </Grid>
                </>
              )}
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Controller
                      name="hasImage"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => <Checkbox {...field} />}
                    />
                  }
                  label="Has Image"
                />
              </Grid>
              {hasImage && (
                <Grid item xs={12} sm={6}>
                  <ImageUploader onUploadSuccess={handleUploadsuccess} />
                </Grid>
              )}
            </Grid>

            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ backgroundColor: "#277d97" }}
              >
                Add Offer
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
    </>
  );

  // Rest of Form

  const [Offers, setOffers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [editingId, setEditingId] = React.useState("");
  const [editingOffer, setEditingOffer] = React.useState({});

  const fetchData = async () => {
    setLoading(true);
    const result = await axios("https://api.freestore.io/offers");
    // If data is not "none"
    if (result.data.data !== 'none') {
      setOffers(result.data);
    }else{
      setOffers([]);
    }
    setLoading(false);
    // "" the editing id
    setEditingId("");
  };

  const handleEdit = (client) => {
    setEditingId(client.offerid);
    setEditingOffer(client);
  };

  const handleConfirm = async () => {
    // Console log the editing client
    console.log(editingOffer);
    // Try result and handle error
    const result = await axios.put(
      `https://api.freestore.io/Offer/${editingId}`,
      editingOffer
    );
    if (result.status === 200) {
      setEditingId("");
      fetchData();
    }
  };

  // Declare Confirm Delete
  const handleConfirmDelete = (id) => {
    setBtnCloseMessage("Cancel");
    setDialogTitle("🚨🚨 Confirm Delete");
    setDialogMessage("Are you sure you want to delete this offer? This will also remove the offer of this type that all the users have claimed in the past.");
    setOpen(true);
    setEditingId(id);
  }

  const handleDelete = async (id) => {
    // Console log url
    console.log(`https://api.freestore.io/Offer/${id}`);
    const result = await axios.delete(`https://api.freestore.io/Offer/${id}`);
    // Deleting the offer console log
    // console.log(result);
    if (result.status === 200) {
      fetchData();
      handleClose();
      setEditingId("");
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const classes = useStyles();

  if (loading) {
    return (
      <div className={classes.progress}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* If dialog title is "🚨🚨 Confirm Delete" add a button*/}
          {dialogTitle === "🚨🚨 Confirm Delete" && (
            <Button onClick={() => handleDelete(editingId)} color="primary">
              🚨 Delete
            </Button>
          )}
          <Button  style={{ backgroundColor: "#277d97",color:'#fff' }} onClick={handleClose} autoFocus>
            {btnCloseMessage}
          </Button>
        </DialogActions>
      </Dialog>
      <Title>Offers</Title>
      {AddOfferForm}
      {/* Add a p */}
      <p>Note : You can only download data for offers with more than 1 Claim.</p>
      <TableContainer>
        <Table size="large" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.header}>Offer Title</TableCell>
              <TableCell className={classes.header}>Total Claims</TableCell>
              <TableCell className={classes.header}>Description</TableCell>
              <TableCell className={classes.header}>Client</TableCell>
              <TableCell className={classes.header}>Promo Code</TableCell>
              <TableCell className={classes.header}>Unlock Code</TableCell>
              <TableCell className={classes.header}>Map URL</TableCell>
              <TableCell className={classes.header}>Read More URL</TableCell>
              <TableCell className={classes.header}>Read More Text</TableCell>
              <TableCell className={classes.header}>Terms</TableCell>
              <TableCell className={classes.header}>Tokens</TableCell>
              <TableCell className={classes.header}>Tokens(Reward)</TableCell>
              <TableCell className={classes.header}>Date Added</TableCell>
              <TableCell className={classes.header}>Featured</TableCell>
              <TableCell className={classes.header}>Edit</TableCell>
              <TableCell className={classes.header}>Delete</TableCell>
              <TableCell className={classes.header}>Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Offers.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            ).map((Offer) => {
              // Get client name of clientid
              const client = clients.find(
                (client) => client.clientID === Offer.clientid
              );
              // Console log clients
              const date = parseISO(Offer.AddedDate);
              const formattedDate = format(date, "eee, MMM dd, yyyy");
              return Offer.offerid === editingId ? (
                <TableRow key={Offer.offerid}>
                  <TableCell>
                    <TextField
                      value={editingOffer.title}
                      onChange={(e) =>
                        setEditingOffer({
                          ...editingOffer,
                          title: e.target.value,
                        })
                      }
                    />
                  </TableCell>
                  <TableCell>{Offer.count}</TableCell>
                  <TableCell>
                    <TextField
                      value={editingOffer.description}
                      onChange={(e) =>
                        setEditingOffer({
                          ...editingOffer,
                          description: e.target.value,
                        })
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      select
                      value={editingOffer.clientid}
                      onChange={(e) =>
                        setEditingOffer({
                          ...editingOffer,
                          clientid: e.target.value,
                        })
                      }
                    >
                      {/* If clients is not empty */}
                      {
                        clients.length > 0 &&
                          clients.map((client) => (
                            <MenuItem
                              key={client.clientID}
                              value={client.clientID}
                            >
                              {client.name}
                            </MenuItem>
                          ))
                        // Else
                      }
                    </TextField>
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={editingOffer.promocode}
                      onChange={(e) =>
                        setEditingOffer({
                          ...editingOffer,
                          promocode: e.target.value,
                        })
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={editingOffer.unlockcode}
                      onChange={(e) =>
                        setEditingOffer({
                          ...editingOffer,
                          unlockcode: e.target.value,
                        })
                      }
                    />
                  </TableCell>
                  {/* Do for google_maps_url */}
                  <TableCell>
                    <TextField
                      value={editingOffer.google_maps_url}
                      onChange={(e) =>
                        setEditingOffer({
                          ...editingOffer,
                          google_maps_url: e.target.value,
                        })
                      }
                    />
                  </TableCell>
                  {/* Do for read_more_url */}
                  <TableCell>
                    <TextField
                      value={editingOffer.read_more_url}
                      onChange={(e) =>
                        setEditingOffer({
                          ...editingOffer,
                          read_more_url: e.target.value,
                        })
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={editingOffer.read_more_description}
                      onChange={(e) =>
                        setEditingOffer({
                          ...editingOffer,
                          read_more_description: e.target.value,
                        })
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={editingOffer.terms}
                      onChange={(e) =>
                        setEditingOffer({
                          ...editingOffer,
                          terms: e.target.value,
                        })
                      }
                    />
                  </TableCell>

                  <TableCell>
                    <TextField
                      type="number"
                      value={editingOffer.tokens}
                      onChange={(e) =>
                        setEditingOffer({
                          ...editingOffer,
                          tokens: parseInt(e.target.value),
                        })
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={editingOffer.tokens_reward}
                      onChange={(e) =>
                        setEditingOffer({
                          ...editingOffer,
                          tokens_reward: parseInt(e.target.value),
                        })
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {/* Make date editable with a date picker */}
                    <TextField
                      type="date"
                      value={editingOffer.AddedDate}
                      onChange={(e) =>
                        setEditingOffer({
                          ...editingOffer,
                          AddedDate: e.target.value,
                        })
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={editingOffer.isFeatured}
                      onChange={(e) =>
                        setEditingOffer({
                          ...editingOffer,
                          isFeatured: e.target.checked,
                        })
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton aria-label="confirm" onClick={handleConfirm}>
                      <CheckIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleConfirmDelete(Offer.offerid)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    {/* Download show if count is greater than 0*/}
                    {Offer.count > 0 && (
                      <IconButton
                      aria-label="download"
                      onClick={() => handleDownload(Offer.offerid)}
                    >
                      <DownloadIcon />
                    </IconButton>
                    )}

                    
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={Offer.offerid}>
                  <TableCell>{Offer.title}</TableCell>
                  <TableCell>{Offer.count}</TableCell>
                  <TableCell>{Offer.description}</TableCell>
                  {/* Show client name of client with clientid */}
                  {/* If client is not empty and client.name is valid */}
                  <TableCell>
                    {clients.length > 0 &&
                      client &&
                      client.name &&
                      client.name}
                  </TableCell>
                  
                  <TableCell>{Offer.promocode}</TableCell>
                  <TableCell>{Offer.unlockcode}</TableCell>
                  {/* Do for google_maps_url */}
                  <TableCell>{Offer.google_maps_url}</TableCell>
                  {/* Do for read_more_url */}
                  <TableCell>{Offer.read_more_url}</TableCell>
                  <TableCell>{Offer.read_more_description}</TableCell>
                  <TableCell>{Offer.terms}</TableCell>
                  <TableCell>{Offer.tokens}</TableCell>
                  <TableCell>{Offer.tokens_reward}</TableCell>
                  <TableCell>{formattedDate}</TableCell>
                  <TableCell>
                    {Offer.isFeatured ? <CheckIcon /> : <CloseIcon />}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleEdit(Offer)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleConfirmDelete(Offer.offerid)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    {/* Download show if offer is greater than 0*/}
                    {Offer.count > 0 && (
                      <IconButton
                      aria-label="download"
                      onClick={() => handleDownload(Offer.offerid)}
                    >
                      <DownloadIcon />
                    </IconButton>
                    )}

                    
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={Offers.length}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) =>
            setRowsPerPage(parseInt(event.target.value, 10))
          }
        />
      </TableContainer>
    </React.Fragment>
  );
}
