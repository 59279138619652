import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://freestore.io/">
        Freestore
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {

    // Declare message state variable and setMessage function
    const [message, setMessage] = React.useState('');

  const handleSubmit = async(event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    
    // Assigning the form data to variables
    const email = data.get('email');
    const password = data.get('password');
    
    // Now, let's use these values in a fetch request.
    try {
      const response = await fetch('https://api.freestore.io/authenticate', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });

      // Let's assume the API returns a JSON object upon success.
      const result = await response.json();
      if (response.ok) {
        console.log("User signed in successfully");
        // Set message
        // setMessage(result.message);
        // Set localstorage signedin true
        localStorage.setItem('signedin', true);
        // Redirect to /qrcodes
        window.location.href = '/home';
        // At this point, you may want to save your user's data somewhere, or redirect them.
      } else {
        console.error("Sign in failed:", result.message);
        // Set message
        setMessage("Wrong Username or Password");
      }
    } catch (err) {
      console.error("Request failed:", err.message);
      setMessage("Wrong Username or Password");
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#277d97' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              onFocus={() => setMessage('')}
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              onFocus={() => setMessage('')}
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
        <div style={{width:'100%',textAlign:'center'}}>
            {/* In a p, red text, show the message */}
            <p style={{color: 'red'}}>{message}</p>
        </div>
            <Button
              type="submit"
              fullWidth
              style={{backgroundColor: '#277d97', color: '#fff'}}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}