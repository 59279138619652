import * as React from "react";
// import useEffect
import { useEffect } from "react";
import { useState} from "react";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import { Grid } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Title from "./Title";
import { getYear, getDaysInMonth,format,parseISO } from "date-fns";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
const useStyles = makeStyles({
  header: {
    fontWeight: "bold",
    textAlign: "left",
  },
  delivered: {
    color: 'green',
    fontWeight: "bold",
    // Underline it
    textDecorationLine: 'underline',
  },
  notDelivered: {
    color: 'red',
  },
  table: {
    textAlign: "center",
    fontWeight: "bold",
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
});

export default function DeliveryTable() {
    const [deliveries, setDeliveries] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
  
    const fetchData = async () => {
      try {
        const response = await axios("https://api.freestore.io/deliveries");
        setDeliveries(response.data.sort((a, b) => new Date(b.Date) - new Date(a.Date)));
        // Console log
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    useEffect(() => {
      fetchData();
    }, []);
  
    const classes = useStyles();
  
    return (
      <React.Fragment>
        <Title>Deliveries</Title>
        <TableContainer>
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.header}>Name</TableCell>
                <TableCell className={classes.header}>Email</TableCell>
                <TableCell className={classes.header}>Order ID</TableCell>
                <TableCell className={classes.header}>Items</TableCell>
                <TableCell className={classes.header}>Date</TableCell>
                <TableCell className={classes.header}>Address</TableCell>
                <TableCell className={classes.header}>Delivered</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deliveries
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((delivery) => (
                  <TableRow key={delivery._id}>
                    <TableCell>{delivery.Name}</TableCell>
                    <TableCell>{delivery.Email}</TableCell>
                    <TableCell>{delivery.OrderID}</TableCell>
                    <TableCell>
                    {delivery.Items.map(item => `${item.count} x ${item.name}`).join( " , ")}
                  </TableCell>
                    <TableCell>{delivery.Date}</TableCell>
                    <TableCell>{delivery.Address}</TableCell>
                    <TableCell style={{ color: delivery.Delivered ? 'green' : 'red' }}>
  {delivery.Delivered ? "Yes" : "No"}
</TableCell></TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={deliveries.length}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(event) =>
              setRowsPerPage(parseInt(event.target.value, 10))
            }
          />
        </TableContainer>
      </React.Fragment>
    );
  }
