import * as React from "react";
import { useState} from "react";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import { Grid } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Title from "./Title";
import { getYear, getDaysInMonth,format,parseISO } from "date-fns";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
const useStyles = makeStyles({
  header: {
    fontWeight: "bold",
    textAlign: "left",
  },
  table: {
    textAlign: "center",
    fontWeight: "bold",
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
});
export default function Clients() {
  const [error, setError] = useState("");
  const generateOptions = (start, end) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push(i);
    }
    return options;
  };
  const years = generateOptions(getYear(new Date()) - 100, getYear(new Date()));
  const days = generateOptions(1, getDaysInMonth(new Date()));
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const addclient = async (data) => {
    setError("");
    const finalData = {
      ...data,
    };
    console.log(finalData);
    try {
      const response = await axios.post(
        `https://api.freestore.io/client`,
        finalData
      );
      if (response.status === 200) {
        // fetchData();
        console.log("client added successfully!");
        // Clear the data in the form from control
        reset();
        // Refresh the component
        fetchData();
      }
    } catch (error) {
      console.error("There was an error!", error);
      // Set the error
      setError(
        " There was an error creating the client. Please try another email or check your connection."
      );
    }
  };
const addclientForm = (
  <>
    <Card variant="outlined">
      <CardContent>
        <Title>Add a new client</Title>
        <form onSubmit={handleSubmit(addclient)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box mb={2}>
                <InputLabel htmlFor="name">Name</InputLabel>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Name is required." }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={Boolean(errors.name)}
                      helperText={errors.name?.message}
                    />
                  )}
                />
              </Box>
              <Box mb={2}>
                <Controller
                  name="clientID"
                  control={control}
                  defaultValue={""} // Default value should be set accordingly.
                  render={({ field }) => (
                    <input type="hidden" {...field} />
                  )}
                />
              </Box>
              <Box mb={2}>
                <Controller
                  name="addedDate"
                  control={control}
                  defaultValue={new Date().toISOString().substring(0, 10)} // Default value is current date
                  render={({ field }) => (
                    <input type="hidden" {...field} />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
          <Box mt={2}>
            {/* If seterror is not empty display in red text */}
            {error && <Typography color="error">{error}</Typography>}
            <br />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onSubmit={addclient}
              style={{ backgroundColor: "#277d97" }}
            >
              Add client
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  </>
);
  // Rest of Form
  const [Clients, setClients] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [editingId, setEditingId] = React.useState("");
  const [editingclient, setEditingclient] = React.useState({});
  const fetchData = async () => {
    setLoading(true);
    const result = await axios("https://api.freestore.io/clients");
    setClients(result.data);
    setLoading(false);
    // "" the editing id
    setEditingId("");
  };
  const handleEdit = (client) => {
    setEditingId(client.clientID);
    setEditingclient(client);
  };
  const handleConfirm = async () => {
    // Console log the editing client
    console.log(editingclient);
    // Try result and handle error
    const result = await axios.put(
      `https://api.freestore.io/client/${editingId}`,
      editingclient
    );
    if (result.status === 200) {
      setEditingId("");
      fetchData();
    }
  };
  const handleDelete = async (id) => {
    const result = await axios.delete(`https://api.freestore.io/client/${id}`);
    if (result.status === 200) {
      fetchData();
    }
  };
  React.useEffect(() => {
    fetchData();
  }, []);
  const classes = useStyles();
  if (loading) {
    return (
      <div className={classes.progress}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <React.Fragment>
      <Title>Clients</Title>
      {addclientForm}
      <TableContainer>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              {/* your header */}
              <TableCell className={classes.header}>Client Name</TableCell>
              <TableCell className={classes.header}>Date Added</TableCell>
              <TableCell className={classes.header}>Edit</TableCell>
              <TableCell className={classes.header}>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Clients
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .reverse().map((client) => {
                const date = parseISO(client.addedDate);
                const formattedDate = format(date, 'eee, MMM dd, yyyy');
                return client.clientID === editingId ? (
                  <TableRow key={client.clientID}>
                    <TableCell>
                      <TextField
                        value={editingclient.name}
                        onChange={(e) =>
                          setEditingclient({
                            ...editingclient,
                            name: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                      type="date"
                        value={editingclient.addedDate}
                        onChange={(e) =>
                          setEditingclient({
                            ...editingclient,
                            addedDate: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton aria-label="confirm" onClick={handleConfirm}>
                        <CheckIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      {/* If the client.name is not "Freestore" or "None" or None show a p with invalid*/}
                      {client.name !== "Freestore" && client.name !== "None" && client.name !== "none" ? (
                          <IconButton
                          aria-label="delete"
                          onClick={() => handleDelete(client.clientID)}
                        >
                          <DeleteIcon />
                        </IconButton>
                        )
                        : (
                          <></>
                        )
                      }
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow key={client.clientID}>
                    {/* your normal table cells here */}
                    <TableCell>{client.name}</TableCell>
                    <TableCell>{formattedDate}</TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleEdit(client)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      {/* If the client.name is not "Freestore" */}
                      {/* If the client.name is not "Freestore" or "None" or None show a p with invalid*/}
                      {client.name !== "Freestore" && client.name !== "None" && client.name !== "none" ? (
                          <IconButton
                          aria-label="delete"
                          onClick={() => handleDelete(client.clientID)}
                        >
                          <DeleteIcon />
                        </IconButton>
                        )
                        : (
                          <></>
                        )
                      }
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={Clients.length}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) =>
            setRowsPerPage(parseInt(event.target.value, 10))
          }
        />
      </TableContainer>
    </React.Fragment>
  );
}
