import * as React from "react";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import { Grid } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Title from "./Title";
import { getYear, getDaysInMonth, format, parseISO } from "date-fns";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

const useStyles = makeStyles({
  header: {
    fontWeight: "bold",
    textAlign: "left",
  },
  table: {
    textAlign: "center",
    fontWeight: "bold",
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
});

export default function Tags() {
  // Declare a string state for error levels
  const [error, setError] = useState("");
  // Ads
  const [ads, setAds] = React.useState([]);
  //   Declare Offers
  const [offers, setOffers] = React.useState([]);
  // Declare products
  const [products, setProducts] = React.useState([]);

  // Declare a state to hold whether current data is ad or offer
  const [currentData, setCurrentData] = useState("ad");
  // Declare current data value
  const [currentDataValue, setCurrentDataValue] = useState("");
  // Declare currentDataValueDisplay
  const [currentDataValueDisplay, setCurrentDataValueDisplay] = useState("");

  // Rest of Form

  const [Tags, setTags] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [editingId, setEditingId] = React.useState("");
  const [editingtag, setEditingtag] = React.useState({});
  const [clients, setClients] = React.useState([]);
  // Declare currenttagtoadd
  const [currenttagtoadd, setCurrenttagtoadd] = useState("");

  // Declare a function to setAd
  const setAd = (ad) => {
    // Define the base API URL
    const baseApiUrl = "https://api.freestore.io";
    // Check if currentDataValue is an offer or an ad
    let updateUrl = "";
    updateUrl = `${baseApiUrl}/ad/${ad.adid}`;
    // axios get and set response as setCurrentDataValue
    axios.get(updateUrl).then((response) => {
      // console log response
      console.log(response.data);
      setCurrentDataValue(response.data);
    });
    setCurrentData("ad");
    // Set current data value display
    setCurrentDataValueDisplay(ad.AdNameFull);
    // Console log ad set
    // console.log(ad);
  };

  // Implement handleTagDelete(tag)
  const handleTagDelete = async (tag) => {
    try {
      // Add the new tag to the tags array of currentDataValue if it doesn't exist already
      // Remove the tag from the tags array of currentDataValue
      const index = currentDataValue.tags.indexOf(tag);
      if (index > -1) {
        currentDataValue.tags.splice(index, 1);
      }

      // Define the base API URL
      const baseApiUrl = "https://api.freestore.io";

      // Check if currentDataValue is an offer or an ad
      let updateUrl = "";
      if ("offerid" in currentDataValue) {
        updateUrl = `${baseApiUrl}/offer/${currentDataValue.offerid}`;
      } else if ("adid" in currentDataValue) {
        updateUrl = `${baseApiUrl}/ad/${currentDataValue.adid}`;
      } else if("productid" in currentDataValue) {
        updateUrl = `${baseApiUrl}/product/${currentDataValue.productid}`;
      } else {
        throw new Error("Provided data is neither an ad nor an offer or product");
      }

      // Remove the _id property before sending the PUT request
      delete currentDataValue._id;

      // Send a PUT request to the API to update the ad or offer
      const response = await axios.put(updateUrl, currentDataValue);

      // Refresh the currentDataValue
      const response_data = await axios.get(updateUrl, currentDataValue);
      setCurrentDataValue(response_data.data);
      // Console log currentDataValue
      console.log(currentDataValue);

      // Log success message
      console.log("Data updated successfully", response.data);
      // Clear current tag to add
      setCurrenttagtoadd("");
    } catch (err) {
      // Log error message
      console.log("Error while updating data", err);
    }
  };

  // Function to add a tag and update an offer or ad
  const updateDataWithNewTag = async (currentDataValue, currentTagToAdd) => {
    try {
      // Add the new tag to the tags array of currentDataValue if it doesn't exist already
      if (!currentDataValue.tags.includes(currentTagToAdd)) {
        currentDataValue.tags.push(currentTagToAdd);
      }

      // Define the base API URL
      const baseApiUrl = "https://api.freestore.io";

      // Check if currentDataValue is an offer or an ad
      let updateUrl = "";
      if ("offerid" in currentDataValue) {
        updateUrl = `${baseApiUrl}/offer/${currentDataValue.offerid}`;
      } else if ("adid" in currentDataValue) {
        updateUrl = `${baseApiUrl}/ad/${currentDataValue.adid}`;
      }else if("productid" in currentDataValue) {
        updateUrl = `${baseApiUrl}/product/${currentDataValue.productid}`;
      }
      else {
        throw new Error("Provided data is neither an ad nor an offer");
      }

      // Remove the _id property before sending the PUT request
      delete currentDataValue._id;

      // Send a PUT request to the API to update the ad or offer
      const response = await axios.put(updateUrl, currentDataValue);

      // Refresh the currentDataValue
      const response_data = await axios.get(updateUrl, currentDataValue);
      setCurrentDataValue(response_data.data);
      // Console log currentDataValue
      console.log(currentDataValue);

      // Log success message
      console.log("Data updated successfully", response.data);
      // Clear current tag to add
      setCurrenttagtoadd("");
    } catch (err) {
      // Log error message
      console.log("Error while updating data", err);
    }
  };

  // Implement AddTagToData
  const AddTagToData = (tag) => {
    // Set current tag to add
    setCurrenttagtoadd(tag);
    // Console log tag
    console.log(tag);
    updateDataWithNewTag(currentDataValue, tag);
  };

  // Implement selectother
  const selectother = () => {
    setCurrentDataValueDisplay("");
    setCurrentDataValue("");
    // Clear current tag to add
    setCurrenttagtoadd("");
    // Console log cleared
    console.log("Cleared");
  };

  // Declare a function to setOffer
  const setOffer = (offer) => {
    // Define the base API URL
    const baseApiUrl = "https://api.freestore.io";
    // Check if currentDataValue is an offer or an ad
    let updateUrl = "";
    updateUrl = `${baseApiUrl}/offer/${offer.offerid}`;
    // axios get and set response as setCurrentDataValue
    axios.get(updateUrl).then((response) => {
      // Console log response.data
      console.log(response.data);
      setCurrentDataValue(response.data);
    });
    setCurrentData("offer");
    setCurrentDataValue(offer);
    // Set current data value display
    setCurrentDataValueDisplay(offer.OfferNameFull);
    // console log offer set
    console.log(offer);
  };

  // Declare function for product
  const setProduct = (product) => {
    // Define the base API URL
    const baseApiUrl = "https://api.freestore.io";
    // Check if currentDataValue is an offer or an ad
    let updateUrl = "";
    updateUrl = `${baseApiUrl}/product/${product.productid}`;
    // axios get and set response as setCurrentDataValue
    axios.get(updateUrl).then((response) => {
      // Console log response.data
      console.log(response.data);
      setCurrentDataValue(response.data);
    });
    setCurrentData("product");
    setCurrentDataValue(product);
    // Set current data value display
    setCurrentDataValueDisplay(product.ProductNameFull);
    // console log product set
    console.log(product);
  };


  // Generate options for select
  const generateOptions = (start, end) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push(i);
    }
    return options;
  };

  const years = generateOptions(getYear(new Date()) - 100, getYear(new Date()));
  const days = generateOptions(1, getDaysInMonth(new Date()));

  // Add Data Form
  // Add tag form in a card
  // Initialize form validation
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  // Add tag function
  const addtag = async (data) => {
    // Set error to none
    setError("");
    // Log the data
    // Auto generate password and append it to body

    // Merge dateOfBirth into form data
    const finalData = {
      ...data,
    };
    console.log(finalData);
    try {
      const response = await axios.post(
        `https://api.freestore.io/tag`,
        finalData
      );
      if (response.status === 200) {
        // fetchData();
        console.log("tag added successfully!");
        // Clear the data in the form from control
        reset();
        // Refresh the component
        fetchData();
      }
    } catch (error) {
      console.error("There was an error!", error);
      // Set the error
      setError(
        " There was an error creating the tag. Please try another email or check your connection."
      );
    }
  };

  // Inside your component...
  const addtagForm = (
    <>
      <Card variant="outlined">
        <CardContent>
          <Title>Add a new tag</Title>
          <form onSubmit={handleSubmit(addtag)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box mb={2}>
                  <InputLabel htmlFor="name">Name</InputLabel>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Name is required." }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={Boolean(errors.name)}
                        helperText={errors.name?.message}
                      />
                    )}
                  />
                </Box>

                <Box mb={2}>
                  <Controller
                    name="tagID"
                    control={control}
                    defaultValue={""} // Default value should be set accordingly.
                    render={({ field }) => <input type="hidden" {...field} />}
                  />
                </Box>

                <Box mb={2}>
                  <Controller
                    name="addedDate"
                    control={control}
                    defaultValue={new Date().toISOString().substring(0, 10)} // Default value is current date
                    render={({ field }) => <input type="hidden" {...field} />}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box mt={2}>
              {/* If seterror is not empty display in red text */}
              {error && <Typography color="error">{error}</Typography>}
              <br />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onSubmit={addtag}
                style={{ backgroundColor: "#277d97" }}
              >
                Add tag
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
    </>
  );

  // Inside your component...
  const editAdtagForm = (
    <>
      <Card variant="outlined">
        <CardContent>
          <Title>Modify Existing Ad/Offer Tags</Title>
          {/* Show all ads and offers by mapping and printing */}
          {/* {ads.map((ad) => (
                <p key={ad.AdNameFull}>{ad.AdNameFull}</p>
            ))} */}
          {/* Offer */}
          {/* <p>Offer</p>
            {offers.map((offer) => (
                <p key={offer.offerid}>{offer.OfferNameFull}</p>
            ))} */}
          {/* Show a p if the currentDataValue display is empty */}
          {currentDataValueDisplay === "" && (
            <Grid container spacing={2}>
              <Grid item xs={4} sm={4}>
                <TextField
                  select
                  label="Either choose an ad"
                  value={currentDataValueDisplay}
                  error={Boolean(errors.AdNameFull)}
                  onChange={(e) => setAd(e.target.value)}
                  helperText={errors.AdNameFull?.message}
                  fullWidth
                >
                  {/* Implement the Ads ad MenuItem */}
                  {ads.length > 0 &&
                    ads.map((ad) => (
                      <MenuItem key={ad.adid} value={ad}>
                        {ad.AdNameFull}
                      </MenuItem>
                    ))}

                  {/* If ad length is less than 0 */}
                  {ads.length <= 0 && (
                    <MenuItem value="">
                      <em>No ads available</em>
                    </MenuItem>
                  )}
                </TextField>
              </Grid>

              <Grid item xs={4} sm={4}>
                <TextField
                  select
                  label="or choose an offer"
                  error={Boolean(errors.OfferNameFull)}
                  value={currentDataValueDisplay}
                  helperText={errors.OfferNameFull?.message}
                  onChange={(e) => setOffer(e.target.value)}
                  fullWidth
                >
                  {/* Implement the Offers ad MenuItem */}
                  {offers.length > 0 &&
                    offers.map((offer) => (
                      <MenuItem key={offer.offerid} value={offer}>
                        {offer.OfferNameFull}
                      </MenuItem>
                    ))}
                  {/* If offer length is less than 0 */}
                  {offers.length <= 0 && (
                    <MenuItem value="">
                      <em>No offers available</em>
                    </MenuItem>
                  )}
                </TextField>
              </Grid>

              {/* Do same for products */}
              <Grid item xs={4} sm={4}>
                <TextField
                  select
                  label="or choose a product"
                  error={Boolean(errors.ProductNameFull)}
                  value={currentDataValueDisplay}
                  helperText={errors.ProductNameFull?.message}
                  onChange={(e) => setProduct(e.target.value)}
                  fullWidth
                >
                  {/* Implement the Products ad MenuItem */}
                  {products.length > 0 &&
                    products.map((product) => (
                      <MenuItem key={product.productid} value={product}>
                        {product.ProductNameFull}
                      </MenuItem>
                    ))}
                  {/* If product length is less than 0 */}
                  {products.length <= 0 && (
                    <MenuItem value="">
                      <em>No products available</em>
                    </MenuItem>
                  )}
                </TextField>
              </Grid>
            </Grid>
          )}
          <br />

          {/* If currentDataValueDisplay is not empty, show a button to cancel it in a grid */}
          {currentDataValueDisplay !== "" && (
            <Button
              variant="contained"
              color="primary"
              onClick={selectother}
              style={{ backgroundColor: "#277d97" }}
            >
              Choose another ad or offer
            </Button>
          )}

          <Grid container spacing={2}>
            {/* If currentDataValueDisplay exists print it */}
            {currentDataValueDisplay && (
              <>
                <Grid item xs={6} sm={6}>
                  <h3>
                    You are now editing the {currentData} for{" "}
                    {currentDataValueDisplay}
                  </h3>
                </Grid>
                <Grid item xs={6} sm={6}>
                  {/* Create a select for all the tags */}
                  <TextField
                    select
                    label="Choose a tag to add"
                    value={currenttagtoadd}
                    onChange={(e) => AddTagToData(e.target.value)}
                    fullWidth
                  >
                    {/* Implement the Tags ad MenuItem */}
                    {Tags.length > 0 &&
                      Tags.map((tag) => (
                        <MenuItem key={tag.tagID} value={tag.name}>
                          {tag.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </>
            )}
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} sm={6}>
              <Stack direction="row" spacing={1}>
                {/* Get all the tags of current data and map it as chips */}
                {currentDataValue.tags &&
                  currentDataValue.tags.map((tag) => (
                    // Implement chip, handle delete with a parameter
                    <Chip
                      label={tag}
                      key={tag}
                      onDelete={() => handleTagDelete(tag)}
                    />
                  ))}
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );

  const fetchData = async () => {
    setLoading(true);
    const result = await axios("https://api.freestore.io/tags");
    setTags(result.data);

    // "" the editing id
    setEditingId("");
    // Get ad and offer data

    // Clients
    const clientsResult = await axios("https://api.freestore.io/clients");
    setClients(clientsResult.data);
    // Print Clients
    // console.log(clientsResult.data);
    // Create a easy to look up hashmap from clientID and CLient Names
    const clientMap = {};
    clientsResult.data.forEach((client) => {
      clientMap[client.clientID] = client.name;
    });
    // Print clientMap
    // console.log(clientMap);

    // Ads
    const adsResult = await axios("https://api.freestore.io/ads");

    if (adsResult.data.data !== "none") {
      // In the adResult, add a new property called "name" and set it to the client name
      adsResult.data.forEach((ad) => {
        // Look up client name from clientMap
        let result = clientMap[ad.clientid];
        // Console log result
        //   Trim the result to 5 characters
        if (result) {
          result = result.substring(0, 10);
        } else {
          result = "No Client";
        }
        ad.AdNameFull = result + "..." + ad.title.substring(0, 20) + "...";
      });
      setAds(adsResult.data);
    }
    // Print ads
    // console.log(adsResult.data);
    // Dos same for offers
    const offersResult = await axios("https://api.freestore.io/offers");
    if (offersResult.data.data !== "none") {
      offersResult.data.forEach((offer) => {
        // Look up client name from clientMap
        let result = clientMap[offer.clientid];
        // Console log result
        //   Trim the result to 5 characters
        // If result is valid
        if (result) {
          result = result.substring(0, 10);
        } else {
          result = "No Client";
        }
        offer.OfferNameFull =
          result + "..." + offer.title.substring(0, 20) + "...";
      });
      setOffers(offersResult.data);
    }
    // print offers

    // Products
    const productsResult = await axios("https://api.freestore.io/products");
    if (productsResult.data.data !== "none") {
      productsResult.data.forEach((product) => {
        // Look up client name from clientMap
        let result = clientMap[product.clientid];
        // Console log result
        //   Trim the result to 5 characters
        // If result is valid
        if (result) {
          result = result.substring(0, 10);
        } else {
          result = "No Client";
        }
        product.ProductNameFull =
          result + "..." + product.title.substring(0, 20) + "...";
      });
      setProducts(productsResult.data);
    }

    // console.log(offersResult.data);
    setLoading(false);
  };

  const handleEdit = (tag) => {
    setEditingId(tag.tagID);
    setEditingtag(tag);
  };

  const handleConfirm = async () => {
    // Console log the editing tag
    console.log(editingtag);
    // Try result and handle error
    const result = await axios.put(
      `https://api.freestore.io/tag/${editingId}`,
      editingtag
    );
    if (result.status === 200) {
      setEditingId("");
      fetchData();
    }
  };

  const handleDelete = async (id) => {
    const result = await axios.delete(`https://api.freestore.io/tag/${id}`);
    if (result.status === 200) {
      fetchData();
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const classes = useStyles();

  if (loading) {
    return (
      <div className={classes.progress}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Title>Tags</Title>
      {addtagForm}
      <hr />
      {editAdtagForm}
      <TableContainer>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              {/* your header */}
              <TableCell className={classes.header}>Tag Name</TableCell>
              <TableCell className={classes.header}>Date Added</TableCell>
              <TableCell className={classes.header}>Edit</TableCell>
              <TableCell className={classes.header}>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Tags.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .reverse()
              .map((tag) => {
                const date = parseISO(tag.addedDate);
                const formattedDate = format(date, "eee, MMM dd, yyyy");
                return tag.tagID === editingId ? (
                  <TableRow key={tag.tagID}>
                    <TableCell>
                      <TextField
                        value={editingtag.name}
                        onChange={(e) =>
                          setEditingtag({
                            ...editingtag,
                            name: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="date"
                        value={editingtag.addedDate}
                        onChange={(e) =>
                          setEditingtag({
                            ...editingtag,
                            addedDate: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton aria-label="confirm" onClick={handleConfirm}>
                        <CheckIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDelete(tag.tagID)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow key={tag.tagID}>
                    {/* your normal table cells here */}
                    <TableCell>{tag.name}</TableCell>
                    <TableCell>{formattedDate}</TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleEdit(tag)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDelete(tag.tagID)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={Tags.length}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) =>
            setRowsPerPage(parseInt(event.target.value, 10))
          }
        />
      </TableContainer>
    </React.Fragment>
  );
}
